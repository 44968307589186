<template>
  <div class="explorer">
    <div v-if="type == 'questions'" class="interface questions">
      <v-row no-gutters>
        <slot>
          <v-col
            :cols="VideoColWidth"
            class="video-col"
            :class="{ small: ShowOptions }"
          >
            <div class="videos">
              <div
                class="window"
                :class="{ paused: Paused }"
                @click="PlayPause"
              ></div>
              <transition name="fadebuffer">
                <div class="buffering" v-show="Buffering"></div>
              </transition>
              <transition name="fadebuffer">
                <div class="overrun" v-show="Overrun"></div>
              </transition>
              <vimeo-player
                ref="VidPopup"
                :video-id="VideoData[id].Vimeo"
                class="video-embed"
                :options="{
                  responsive: true,
                  controls: false,
                  autopause: false
                }"
                @bufferstart="SetBuffering(true)"
                @bufferend="SetBuffering(false)"
                @ended="$modal.hide('explorer')"
                @timeupdate="TimeUpdate"
              />
              <span
                class="captions"
                :class="{ enabled: Captions }"
                @click="toggleCaptions"
                >Captions</span
              >
            </div>
          </v-col>
          <transition name="fade">
            <v-col cols="5" class="options-col" v-show="ShowOptionsUI">
              <div class="options fill-height">
                <slot v-if="!SecondSetOfQuestions">
                  <div
                    class="option"
                    @click="SetTime(VideoData[id].FirstAnswerStart)"
                  >
                    <span>Did you hate Edward Fitch?</span>
                  </div>
                </slot>

                <slot v-if="SecondSetOfQuestions">
                  <div
                    class="option"
                    @click="SetTime(VideoData[id].First.Start)"
                  >
                    <span>{{ VideoData[id].First.Text }}</span>
                  </div>
                  <div
                    class="option"
                    @click="SetTime(VideoData[id].Second.Start)"
                  >
                    <span>{{ VideoData[id].Second.Text }}</span>
                  </div>
                  <div
                    class="option"
                    @click="SetTime(VideoData[id].Third.Start)"
                  >
                    <span>{{ VideoData[id].Third.Text }}</span>
                  </div>
                </slot>
              </div>
            </v-col>
          </transition>
        </slot>
      </v-row>
      <div class="close" @click="$modal.hide('explorer')"></div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      Buffering: true,
      Captions: false,
      Paused: false,
      Overrun: false,
      ShowOptionsUI: false,
      Time: 0,
      VideoData: [
        {
          Room: "Scenic Workshop",
          Vimeo: "532827643",
          FirstOptionSecs: 12,
          FirstAnswerStart: 24,
          SecondOptionSecs: 81,
          First: {
            Text: "Why are you at the theatre?",
            Start: 92,
            Stop: 120
          },
          Second: {
            Text: "Why did you tell Ethel about your sister tonight?",
            Start: 130,
            Stop: 199
          },
          Third: {
            Text: "Did you plan to kill Fitch?",
            Start: 210,
            Stop: 254
          }
        },
        {
          Room: "Prop Store",
          Vimeo: "532827585",
          FirstOptionSecs: 14,
          FirstAnswerStart: 25,
          SecondOptionSecs: 130,
          First: {
            Text: "Was Fitch blackmailing you?",
            Start: 141,
            Stop: 177
          },
          Second: {
            Text: "Does your wife know about your affair with Norma?",
            Start: 188,
            Stop: 218
          },
          Third: {
            Text: "Do you come here every week?",
            Start: 229,
            Stop: 256
          }
        },
        {
          Room: "Dressing Room",
          Vimeo: "532827547",
          FirstOptionSecs: 14,
          FirstAnswerStart: 24,
          SecondOptionSecs: 108,
          First: {
            Text: "Did Fitch ruin your life?",
            Start: 119,
            Stop: 205
          },
          Second: {
            Text: "Why are you here tonight?",
            Start: 216,
            Stop: 290
          },
          Third: {
            Text: "Where were you just before the show started?",
            Start: 301,
            Stop: 349
          }
        },
        {
          Room: "Shop",
          Vimeo: "532827611",
          FirstOptionSecs: 13,
          FirstAnswerStart: 22,
          SecondOptionSecs: 137,
          First: {
            Text: "Where did you go with Fitch's coffee?",
            Start: 148,
            Stop: 223
          },
          Second: {
            Text: "Tell me more about your mum...",
            Start: 235,
            Stop: 271
          },
          Third: {
            Text: "Do you enjoy your job?",
            Start: 282,
            Stop: 347
          }
        },
        {
          Room: "Wardrobe",
          Vimeo: "532827664",
          FirstOptionSecs: 12,
          FirstAnswerStart: 22,
          SecondOptionSecs: 110,
          First: {
            Text: "Did Fitch invite you here tonight?",
            Start: 121,
            Stop: 150
          },
          Second: {
            Text: "Why were you so upset just before the show?",
            Start: 161,
            Stop: 217
          },
          Third: {
            Text: "How long have you and Fitch been together?",
            Start: 228,
            Stop: 288
          }
        }
      ]
    };
  },
  props: ["id", "area", "type"],
  computed: {
    VideoColWidth() {
      if (this.ShowOptions) {
        return 7;
      } else {
        return 12;
      }
    },
    ShowOptions() {
      let test;
      // First set of Qs
      let t1 = this.VideoData[this.id].FirstOptionSecs;
      let t2 = t1 + 10;

      // Second set of Qs
      let t3 = this.VideoData[this.id].SecondOptionSecs;
      let t4 = t3 + 10;

      // Second set of Qs - Q1
      let t5 = this.VideoData[this.id].First.Stop;
      let t6 = t5 + 10;

      // Second set of Qs - Q2
      let t7 = this.VideoData[this.id].Second.Stop;
      let t8 = t7 + 10;

      // Second set of Qs - Q3
      let t9 = this.VideoData[this.id].Third.Stop;
      let t10 = t9 + 10;

      if (this.Time >= t1 && this.Time <= t2) {
        test = true;
      } else if (this.Time >= t3 && this.Time <= t4) {
        test = true;
      } else if (this.Time >= t5 && this.Time <= t6) {
        test = true;
      } else if (this.Time >= t7 && this.Time <= t8) {
        test = true;
      } else if (this.Time >= t9 && this.Time <= t10) {
        test = true;
      } else {
        test = false;
      }

      if (test) {
        return true;
      } else {
        return false;
      }
    },
    OverrunTest() {
      let test;
      // First set of Qs
      let t1 = this.VideoData[this.id].FirstOptionSecs + 9;
      let t2 = t1 + 1;

      // Second set of Qs
      let t3 = this.VideoData[this.id].SecondOptionSecs + 9;
      let t4 = t3 + 1;

      // Second set of Qs - Q1
      let t5 = this.VideoData[this.id].First.Stop + 9;
      let t6 = t5 + 1;

      // Second set of Qs - Q2
      let t7 = this.VideoData[this.id].Second.Stop + 9;
      let t8 = t7 + 1;

      // Second set of Qs - Q3
      let t9 = this.VideoData[this.id].Third.Stop + 9;
      let t10 = t9 + 1;

      if (this.Time >= t1 && this.Time <= t2) {
        test = true;
      } else if (this.Time >= t3 && this.Time <= t4) {
        test = true;
      } else if (this.Time >= t5 && this.Time <= t6) {
        test = true;
      } else if (this.Time >= t7 && this.Time <= t8) {
        test = true;
      } else if (this.Time >= t9 && this.Time <= t10) {
        test = true;
      } else {
        test = false;
      }

      if (test) {
        return true;
      } else {
        return false;
      }
    },
    SecondSetOfQuestions() {
      if (this.Time > 60) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    SetBuffering(val) {
      this.Buffering = val;
    },
    PlayPause() {
      if (this.Paused) {
        this.$refs.VidPopup.play().then(() => {
          this.Paused = false;
        });
      } else {
        this.$refs.VidPopup.pause().then(() => {
          this.Paused = true;
        });
      }
    },
    TimeUpdate(val) {
      this.Time = val.seconds;
    },
    SetTime(secs) {
      this.$refs.VidPopup.player.setCurrentTime(secs).then(() => {
        this.$refs.VidPopup.play();
        this.Overrun = false;
      });
    },
    toggleCaptions() {
      if (this.Captions) {
        console.log("Disable captions");
        this.$refs.VidPopup.player.disableTextTrack().then(() => {
          this.Captions = false;
          this.$store.commit("disablecc");
        });
      } else {
        console.log("Enable captions");
        this.$refs.VidPopup.player.enableTextTrack("en-GB").then(() => {
          this.Captions = true;
          this.$store.commit("enablecc");
        });
      }
    }
  },
  watch: {
    OverrunTest(val) {
      if (val) {
        this.Overrun = true;
        this.$refs.VidPopup.pause();
      }
    },
    ShowOptions(val) {
      if (val) {
        setTimeout(() => {
          this.ShowOptionsUI = true;
        }, 2000);
      } else {
        this.ShowOptionsUI = false;
      }
    }
  },
  mounted() {
    this.$refs.VidPopup.play();
    if (this.$store.state.captions) {
      this.$refs.VidPopup.player.enableTextTrack("en-GB").then(() => {
        this.Captions = true;
      });
    } else {
      this.$refs.VidPopup.player.disableTextTrack().then(() => {
        this.Captions = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.fadebuffer-enter-active,
.fadebuffer-leave-active {
  transition: 0.7s;
}
.fadebuffer-enter, .fadebuffer-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.explorer {
  background: url(../assets/popup-bg.png) no-repeat;
  background-size: 100% 100%;
  padding: 60px;

  .interface {
    text-align: center;

    .close {
      display: inline-block;
      width: 300px;
      height: 59px;
      background: url(../assets/back-to-map.png) no-repeat;
      background-size: 100% 100%;
      margin-top: 25px;
      cursor: pointer !important;

      &:hover {
        background-image: url(../assets/back-to-map-hv.png);
      }
    }
  }

  .videos {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    border: 10px double black;

    &:hover .captions {
      opacity: 1;
    }

    .window {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      cursor: url(../assets/cursor-pause.png), auto;

      &.paused {
        cursor: url(../assets/cursor-play.png), auto;
      }
      &.overrun {
        background: url(../assets/ask-a-question.png) no-repeat;
        background-size: 100% 100%;
      }
    }

    .overrun {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: url(../assets/ask-a-question.png) no-repeat;
      background-size: 100% 100%;
    }

    .buffering {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: url(../assets/loading-video-v2.png) no-repeat;
      background-size: 100% 100%;
    }

    .video-embed {
      width: 100%;
    }

    .captions {
      display: block;
      opacity: 0;
      width: 155px;
      height: 92px;
      position: absolute;
      bottom: 25px;
      right: 15px;
      z-index: 10;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background: url(../assets/enable-cc.png) no-repeat;
      background-size: contain;
      cursor: pointer !important;
      transition: 0.3s;

      &.enabled {
        background: url(../assets/disable-cc.png) no-repeat;
        background-size: contain;
      }
    }
  }
}

// For rooms with interogations
.video-col {
  transition: 2s;
  transition-delay: 1.3s;

  &.small {
    transition: 2s;
    transition-delay: 0;
  }
}
.options {
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .option {
    padding: 45px;
    margin-bottom: 25px;
    background: url(../assets/popup-bg-option.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    cursor: pointer !important;
    width: 100%;
    clear: both;

    span {
      color: #fff;
      font-family: "Franchise";
      line-height: 1.7vw;
      font-size: 1.9vw;
    }

    &:hover span {
      color: #f9ee61;
    }
  }
}
</style>
