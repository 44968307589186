<template>
  <v-app>
    <v-main>
      <modal
        name="confirm"
        class="confirm"
        width="800px"
        styles="background: none;"
        height="136px"
      >
        <div class="inner">
          <span class="no" @click="$modal.hide('confirm')">No</span>
          <router-link
            :to="{
              path: '/guess-who',
              query: { d: this.$store.state.viewingdate }
            }"
            class="yes"
            >Yes</router-link
          >
        </div>
      </modal>
      <div class="map-holder" :class="{ popupopen: popupopen }">
        <transition name="fadeslow">
          <img
            v-show="!loaded"
            class="map-initial"
            src="@/assets/map-initial-sml.png"
            width="100%"
          />
        </transition>
        <img class="map" src="@/assets/map-base.png" usemap="#image_map" />
        <map name="image_map">
          <area
            alt=""
            title=""
            coords="1165,1024 746,1024 719,1010 710,984 719,961 746,946 1161,945 1190,964 1194,1006 "
            shape="polygon"
            @click="$modal.show('confirm')"
            @mouseenter="hover.accuse = true"
            @mouseleave="hover.accuse = false"
          />
          <area
            alt=""
            title=""
            @click="explore('scenicworkshop', 'questions', 0)"
            coords="1402,485 1533,485 1533,767 1399,767 "
            shape="polygon"
            @mouseenter="hover.scenicworkshop = true"
            @mouseleave="hover.scenicworkshop = false"
          />
          <area
            alt=""
            title=""
            @click="explore('propstore', 'questions', 1)"
            coords="1755,703 1833,699 1833,708 1862,708 1860,882 1754,882 "
            shape="polygon"
            @mouseenter="hover.propstore = true"
            @mouseleave="hover.propstore = false"
          />
          <area
            alt=""
            title=""
            @click="explore('staffkitchen')"
            coords="1521,456 1518,401 1399,405 1402,458 "
            shape="polygon"
            @mouseenter="hover.staffkitchen = true"
            @mouseleave="hover.staffkitchen = false"
          />
          <area
            alt=""
            title=""
            @click="explore('ladiestoilet')"
            coords="1143,272 1141,309 1056,309 1056,266 "
            shape="polygon"
            @mouseenter="hover.ladiestoilet = true"
            @mouseleave="hover.ladiestoilet = false"
          />
          <area
            alt=""
            title=""
            @click="explore('greenroom')"
            coords="1489,312 1489,394 1397,399 1397,428 1281,430 1280,405 1250,405 1250,362 1225,360 1226,312 "
            shape="polygon"
            @mouseenter="hover.greenroom = true"
            @mouseleave="hover.greenroom = false"
          />
          <area
            alt=""
            title=""
            @click="explore('stagedoor')"
            coords="1464,772 1278,770 1278,808 1454,809 1454,783 "
            shape="polygon"
            @mouseenter="hover.stagedoor = true"
            @mouseleave="hover.stagedoor = false"
          />
          <area
            alt=""
            title=""
            @click="explore('stage')"
            coords="1395,433 1395,765 1274,767 1274,808 1260,808 1260,832 1182,832 1179,713 1166,713 1166,740 1132,740 1131,497 1170,497 1170,531 1184,529 1182,442 1150,445 1152,389 1221,387 1221,364 1244,364 1248,408 1269,408 1280,433 "
            shape="polygon"
            @mouseenter="hover.stage = true"
            @mouseleave="hover.stage = false"
          />
          <area
            alt=""
            title=""
            @click="explore('stalls')"
            coords="914,390 1115,396 1113,685 914,680 "
            shape="polygon"
            @mouseenter="hover.stalls = true"
            @mouseleave="hover.stalls = false"
          />
          <area
            alt=""
            title=""
            @click="explore('stallsbar')"
            coords="1219,386 1217,312 1217,395 874,386 870,372 805,370 805,321 1221,312 "
            shape="polygon"
            @mouseenter="hover.stallsbar = true"
            @mouseleave="hover.stallsbar = false"
          />
          <area
            alt=""
            title=""
            @click="explore('circlebar')"
            coords="392,251 390,291 461,330 656,332 656,291 627,285 627,271 585,271 585,247 562,246 558,164 457,159 453,220 433,220 432,251 "
            shape="polygon"
            @mouseenter="hover.circlebar = true"
            @mouseleave="hover.circlebar = false"
          />
          <area
            alt=""
            title=""
            @click="explore('dressingroom', 'questions', 2)"
            coords="455,110 453,159 560,159 560,110 "
            shape="polygon"
            @mouseenter="hover.dressingroom = true"
            @mouseleave="hover.dressingroom = false"
          />
          <area
            alt=""
            title=""
            @click="explore('laundry')"
            coords="347,179 345,242 424,240 430,168 399,164 399,179 "
            shape="polygon"
            @mouseenter="hover.laundry = true"
            @mouseleave="hover.laundry = false"
          />
          <area
            alt=""
            title=""
            @click="explore('finchoffice')"
            coords="247,173 246,238 199,238 199,177 "
            shape="polygon"
            @mouseenter="hover.finchoffice = true"
            @mouseleave="hover.finchoffice = false"
          />
          <area
            alt=""
            title=""
            @click="explore('shop', 'questions', 3)"
            coords="143,408 141,509 99,509 103,405 "
            shape="polygon"
            @mouseenter="hover.shop = true"
            @mouseleave="hover.shop = false"
          />
          <area
            alt=""
            title=""
            @click="explore('dresscircle')"
            coords="509,332 509,354 401,359 343,386 314,468 336,556 399,598 513,600 513,621 448,621 388,665 271,663 271,623 240,619 224,542 229,383 246,338 271,350 273,287 386,287 459,330 "
            shape="polygon"
            @mouseenter="hover.dresscircle = true"
            @mouseleave="hover.dresscircle = false"
          />
          <area
            alt=""
            title=""
            @click="explore('wardrobe', 'questions', 4)"
            coords="652,730 578,722 578,688 385,692 385,663 455,630 618,628 618,645 636,645 637,616 652,616 "
            shape="polygon"
            @mouseenter="hover.wardrobe = true"
            @mouseleave="hover.wardrobe = false"
          />
        </map>
        <transition-group name="fade">
          <img
            class="map-overlay"
            src="@/assets/map-hover/accuse.png"
            v-show="hover.accuse"
            usemap="#image_map"
            key="accuse"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/scenicworkshop.png"
            v-show="hover.scenicworkshop"
            usemap="#image_map"
            key="scenicworkshop"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/propstore.png"
            v-show="hover.propstore"
            usemap="#image_map"
            key="propstore"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/staffkitchen.png"
            v-show="hover.staffkitchen"
            usemap="#image_map"
            key="staffkitchen"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/ladiestoilet.png"
            v-show="hover.ladiestoilet"
            usemap="#image_map"
            key="ladiestoilet"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/greenroom.png"
            v-show="hover.greenroom"
            usemap="#image_map"
            key="greenroom"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/stagedoor.png"
            v-show="hover.stagedoor"
            usemap="#image_map"
            key="stagedoor"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/stage.png"
            v-show="hover.stage"
            usemap="#image_map"
            key="stage"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/stalls.png"
            v-show="hover.stalls"
            usemap="#image_map"
            key="stalls"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/stallsbar.png"
            v-show="hover.stallsbar"
            usemap="#image_map"
            key="stallsbar"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/circlebar.png"
            v-show="hover.circlebar"
            usemap="#image_map"
            key="circlebar"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/dressingroom.png"
            v-show="hover.dressingroom"
            usemap="#image_map"
            key="dressingroom"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/laundry.png"
            v-show="hover.laundry"
            usemap="#image_map"
            key="laundry"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/finchoffice.png"
            v-show="hover.finchoffice"
            usemap="#image_map"
            key="finchoffice"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/shop.png"
            v-show="hover.shop"
            usemap="#image_map"
            key="shop"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/dresscircle.png"
            v-show="hover.dresscircle"
            usemap="#image_map"
            key="dresscircle"
          />
          <img
            class="map-overlay"
            src="@/assets/map-hover/wardrobe.png"
            v-show="hover.wardrobe"
            usemap="#image_map"
            key="wardrobe"
          />
        </transition-group>
      </div>
    </v-main>

    <!-- <modal
      name="scenicworkshop"
      width="80%"
      styles="background: none;"
      height="auto"
    >
      <map-popup area="scenicworkshop"></map-popup>
    </modal> -->
  </v-app>
</template>

<script>
import imageMapResize from "image-map-resizer";
import MapPopup from "@/components/MapPopup";
import MapPopupStatic from "@/components/MapPopupStatic";
export default {
  name: "Map",
  title: "The Map",
  data() {
    return {
      hover: {
        accuse: false,
        scenicworkshop: false,
        propstore: false,
        staffkitchen: false,
        ladiestoilet: false,
        greenroom: false,
        stagedoor: false,
        stage: false,
        stalls: false,
        stallsbar: false,
        circlebar: false,
        dressingroom: false,
        laundry: false,
        finchoffice: false,
        shop: false,
        dresscircle: false,
        wardrobe: false
      },
      popupopen: false,
      loaded: false
    };
  },
  methods: {
    explore(area, type, id) {
      if (type == "questions") {
        this.$modal.show(
          MapPopup,
          { area: area, type: type, id: id },
          {
            name: "explorer",
            width: "80%",
            styles: "background: none;",
            height: "auto"
          },
          {
            opened: () => {
              this.popupopen = true;
              this.$gtag.event("map_popup_opened", { area: area });
            },
            closed: () => {
              this.popupopen = false;
            }
          }
        );
      } else {
        this.$modal.show(
          MapPopupStatic,
          { area: area, type: type, id: id },
          {
            name: "explorer",
            width: "80%",
            styles: "background: none;",
            height: "auto"
          },
          {
            opened: () => {
              this.popupopen = true;
              this.$gtag.event("map_popup_opened", { area: area });
            },
            closed: () => {
              this.popupopen = false;
            }
          }
        );
      }
    },
    hide() {
      this.$modal.hide("explorer");
    }
  },
  mounted() {
    imageMapResize();
    setTimeout(() => {
      this.loaded = true;
    }, 2000);
  }
};
</script>
<style lang="scss" scoped>
main {
  height: 100vh;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../assets/newspaper-bg.jpg) no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    z-index: 0;
    filter: invert(20%) sepia(0.2) brightness(0.3) blur(4px);
    -webkit-filter: invert(20%) sepia(0.2) brightness(0.3) blur(4px);
  }

  .download-programme {
    filter: invert(20%) sepia(0.2) brightness(0.3) blur(4px);
    -webkit-filter: invert(20%) sepia(0.2) brightness(0.3) blur(4px);
  }

  .video-block {
    position: relative;
    top: 7%;
    margin: 7px 7px;
  }
}

.map-holder {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-52%));
  width: 85%;
  min-height: 800px;
  z-index: 1;
  transition: 0.4s ease-out;
  filter: none;
  -webkit-filter: none;

  &.popupopen {
    filter: brightness(0.8) blur(3px);
    -webkit-filter: brightness(0.8) blur(3px);
  }

  .map-initial {
    display: block;
    max-width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .map {
    display: block;
    max-width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
  }
  .map-overlay {
    max-width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.2s ease-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fadeslow-enter-active,
.fadeslow-leave-active {
  transition: 4s ease-out;
}
.fadeslow-enter, .fadeslow-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

area {
  z-index: 1;
  position: relative;
  cursor: url(../assets/cursor-look.png), auto !important;
}

.confirm {
  .inner {
    background: url(../assets/confirm-accuse.png);
    background-size: 100% 100%;
    width: 800px;
    height: 136px;
    text-align: center;
    padding-top: 85px;

    .no {
      display: inline-block;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background: url(../assets/confirm-no.png) no-repeat;
      background-size: 100% 100%;
      width: 110px;
      height: 34px;
      margin-right: 55px;
      cursor: pointer;

      &:hover {
        background: url(../assets/confirm-no-hv.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    .yes {
      display: inline-block;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background: url(../assets/confirm-yes.png) no-repeat;
      background-size: 100% 100%;
      width: 110px;
      height: 34px;
      cursor: pointer;

      &:hover {
        background: url(../assets/confirm-yes-hv.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
