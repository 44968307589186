<template>
  <div class="explorer">
    <!-- Normal videos -->
    <div class="interface">
      <div class="videos">
        <div
          class="window"
          :class="{ paused: Paused }"
          @click="PlayPause"
        ></div>
        <transition name="fadebuffer">
          <div class="buffering" v-show="Buffering"></div>
        </transition>

        <slot v-if="area == 'staffkitchen'">
          <vimeo-player
            ref="VidPopup"
            video-id="532424601"
            class="video-embed"
            :options="{ responsive: true, controls: false, autopause: false }"
            @bufferstart="SetBuffering(true)"
            @bufferend="SetBuffering(false)"
            @ended="$modal.hide('explorer')"
          />
        </slot>
        <slot v-if="area == 'ladiestoilet'">
          <vimeo-player
            ref="VidPopup"
            video-id="532424178"
            class="video-embed"
            :options="{ responsive: true, controls: false, autopause: false }"
            @bufferstart="SetBuffering(true)"
            @bufferend="SetBuffering(false)"
            @ended="$modal.hide('explorer')"
          />
        </slot>
        <slot v-if="area == 'greenroom'">
          <vimeo-player
            ref="VidPopup"
            video-id="532425538"
            class="video-embed"
            :options="{ responsive: true, controls: false, autopause: false }"
            @bufferstart="SetBuffering(true)"
            @bufferend="SetBuffering(false)"
            @ended="$modal.hide('explorer')"
          />
        </slot>
        <slot v-if="area == 'stagedoor'">
          <vimeo-player
            ref="VidPopup"
            video-id="532425191"
            class="video-embed"
            :options="{ responsive: true, controls: false, autopause: false }"
            @bufferstart="SetBuffering(true)"
            @bufferend="SetBuffering(false)"
            @ended="$modal.hide('explorer')"
          />
        </slot>
        <slot v-if="area == 'stage'">
          <vimeo-player
            ref="VidPopup"
            video-id="532424674"
            class="video-embed"
            :options="{ responsive: true, controls: false, autopause: false }"
            @bufferstart="SetBuffering(true)"
            @bufferend="SetBuffering(false)"
            @ended="$modal.hide('explorer')"
          />
        </slot>
        <slot v-if="area == 'stalls'">
          <vimeo-player
            ref="VidPopup"
            video-id="532424841"
            class="video-embed"
            :options="{ responsive: true, controls: false, autopause: false }"
            @bufferstart="SetBuffering(true)"
            @bufferend="SetBuffering(false)"
            @ended="$modal.hide('explorer')"
          />
        </slot>
        <slot v-if="area == 'stallsbar'">
          <vimeo-player
            ref="VidPopup"
            video-id="532424435"
            class="video-embed"
            :options="{ responsive: true, controls: false, autopause: false }"
            @bufferstart="SetBuffering(true)"
            @bufferend="SetBuffering(false)"
            @ended="$modal.hide('explorer')"
          />
        </slot>
        <slot v-if="area == 'circlebar'">
          <vimeo-player
            ref="VidPopup"
            video-id="532423884"
            class="video-embed"
            :options="{ responsive: true, controls: false, autopause: false }"
            @bufferstart="SetBuffering(true)"
            @bufferend="SetBuffering(false)"
            @ended="$modal.hide('explorer')"
          />
        </slot>
        <slot v-if="area == 'laundry'">
          <vimeo-player
            ref="VidPopup"
            video-id="532425363"
            class="video-embed"
            :options="{ responsive: true, controls: false, autopause: false }"
            @bufferstart="SetBuffering(true)"
            @bufferend="SetBuffering(false)"
            @ended="$modal.hide('explorer')"
          />
        </slot>
        <slot v-if="area == 'finchoffice'">
          <vimeo-player
            ref="VidPopup"
            video-id="532424005"
            class="video-embed"
            :options="{ responsive: true, controls: false, autopause: false }"
            @bufferstart="SetBuffering(true)"
            @bufferend="SetBuffering(false)"
            @ended="$modal.hide('explorer')"
          />
        </slot>
        <slot v-if="area == 'dresscircle'">
          <vimeo-player
            ref="VidPopup"
            video-id="532425024"
            class="video-embed"
            :options="{ responsive: true, controls: false, autopause: false }"
            @bufferstart="SetBuffering(true)"
            @bufferend="SetBuffering(false)"
            @ended="$modal.hide('explorer')"
          />
        </slot>
      </div>
      <div class="close" @click="$modal.hide('explorer')"></div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      Buffering: true,
      Captions: false,
      Paused: false
    };
  },
  props: ["area"],
  methods: {
    SetBuffering(val) {
      this.Buffering = val;
    },
    PlayPause() {
      if (this.Paused) {
        this.$refs.VidPopup.play().then(() => {
          this.Paused = false;
        });
      } else {
        this.$refs.VidPopup.pause().then(() => {
          this.Paused = true;
        });
      }
    }
  },
  mounted() {
    this.$refs.VidPopup.play();
  }
};
</script>

<style lang="scss" scoped>
.fadebuffer-enter-active,
.fadebuffer-leave-active {
  transition: 0.7s;
}
.fadebuffer-enter, .fadebuffer-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.explorer {
  background: url(../assets/popup-bg.png) no-repeat;
  background-size: 100% 100%;
  padding: 60px;

  .interface {
    text-align: center;

    .close {
      display: inline-block;
      width: 300px;
      height: 59px;
      background: url(../assets/back-to-map.png) no-repeat;
      background-size: 100% 100%;
      margin-top: 25px;
      cursor: pointer !important;

      &:hover {
        background-image: url(../assets/back-to-map-hv.png);
      }
    }
  }

  .videos {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    border: 10px double black;

    .window {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      cursor: url(../assets/cursor-pause.png), auto;

      &.paused {
        cursor: url(../assets/cursor-play.png), auto;
      }
    }

    .buffering {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: url(../assets/loading-video-v2.png) no-repeat;
      background-size: 100% 100%;
    }

    .video-embed {
      width: 100%;
    }

    .captions {
      display: block;
      opacity: 0;
      width: 155px;
      height: 92px;
      position: absolute;
      bottom: 25px;
      right: 15px;
      z-index: 10;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background: url(../assets/enable-cc.png) no-repeat;
      background-size: contain;
      cursor: pointer !important;
      transition: 0.3s;

      &.enabled {
        background: url(../assets/disable-cc.png) no-repeat;
        background-size: contain;
      }
    }
  }
}
</style>
